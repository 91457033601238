// Constants
const LOYALTY_POLICY_TEXT = "Reglamento Programa CMR Puntos";
const UPDATE_LOYALTY_TEXT = "¿Desea actualizar el Reglamento de CMR Puntos?";
const ADD_LOYALTY_TEXT = "¿Desea agregar el Reglamento de CMR Puntos?";
const PRIVACY_POLICY_TEXT = "política de privacidad";
const UPDATE_POLICY_TEXT = "¿Desea actualizar la política de privacidad de CMR Puntos?";
const ADD_POLICY_TEXT = "¿Desea agregar la política de privacidad de CMR Puntos?";


import React, { ReactElement, useContext, useEffect, useState } from "react";
import ConsentContext from "../../../context/ConsentContext";
import { useDraftsApprovedByPurposeName } from "../../../hooks/useDraftsApprovedByPurposeName";
import { useUser } from "../../../hooks/useUser";
import UpdateConsent from "./update";
import { NewVersionConsentConfigType } from "../../../types/newVersionConsentConfig";

const findTemplateByDisplayText = (templates: NewVersionConsentConfigType[], displayText: string) => {
  return templates.find((template) => template.display_text.includes(displayText));
}

const UpdateConsentQuery = (): ReactElement => {
  const { user } = useUser();
  const { searchConsentForm } = useContext(ConsentContext);
  const { business, country, purpose } = searchConsentForm;
  const [loyaltyTemplate, setLoyaltyTemplate] = useState({});
  const [labelLoyalty, setLabelLoyalty] = useState("");
  const [loyaltyAction, setLoyaltyAction] = useState("");
  const [hasLoyaltyTemplate, setHasLoyaltyTemplate] = useState(false);
  const [policyTemplate, setPolicyTemplate] = useState({});
  const [labelPolicy, setLabelPolicy] = useState("");
  const [policyAction, setPolicyAction] = useState("");
  const [hasPolicyTemplate, setHasPolicyTemplate] = useState(false);


  const {
    data: dataFetched,
    approvedDraftApprovedQuery,
    draftData
  } = useDraftsApprovedByPurposeName({
    business,
    country,
    purposeName: purpose,
    token: user.token
  });

  useEffect(() => {
    if (approvedDraftApprovedQuery.isSuccess) {
      const loyaltyRuleTemplate = findTemplateByDisplayText(dataFetched, LOYALTY_POLICY_TEXT);

      let newLoyaltyTemplate = {};
      let newLabelLoyalty = ADD_LOYALTY_TEXT;
      let newLoyaltyAction = "add";
      let newHasLoyaltyTemplate = false;

      if (loyaltyRuleTemplate) {
        newLoyaltyTemplate = loyaltyRuleTemplate;
        newLabelLoyalty = UPDATE_LOYALTY_TEXT;
        newLoyaltyAction = "update";
        newHasLoyaltyTemplate = true;
      }

      setLoyaltyTemplate(newLoyaltyTemplate);
      setLoyaltyAction(newLoyaltyAction);
      setLabelLoyalty(newLabelLoyalty);
      setHasLoyaltyTemplate(newHasLoyaltyTemplate);


      const policyRuleTemplate = findTemplateByDisplayText(dataFetched, PRIVACY_POLICY_TEXT);

      let newPolicyTemplate = {};
      let newLabelPolicy = ADD_POLICY_TEXT;
      let newPolicyAction = "add";
      let newHasPolicyTemplate = false;

      if (policyRuleTemplate) {
        newPolicyTemplate = policyRuleTemplate;
        newLabelPolicy = UPDATE_POLICY_TEXT;
        newPolicyAction = "update";
        newHasPolicyTemplate = true;
      }

      setPolicyTemplate(newPolicyTemplate);
      setLabelPolicy(newLabelPolicy);
      setPolicyAction(newPolicyAction);
      setHasPolicyTemplate(newHasPolicyTemplate);

    }
  }, [approvedDraftApprovedQuery.isLoading]);


  if (approvedDraftApprovedQuery.isLoading) {
    return <div>Loading...</div>;
  }


  return (
    <div>
      <UpdateConsent
        dataFetched={dataFetched}
        draftData={draftData}
        business={business}
        country={country}
        purpose={purpose}
        policyOptions={{
          hasPolicyTemplate,
          policyAction,
          policyTemplate,
          labelPolicy
        }}
        loyaltyOptions={{
          hasLoyaltyTemplate,
          loyaltyAction,
          loyaltyTemplate,
          labelLoyalty
        }}
      />
    </div>
  );
};

export default UpdateConsentQuery;
